import { applyMiddleware, createStore } from 'redux';
import RootReducers from './root-reducer';
import logger from 'redux-logger';

let middleware = [];
if (process.env.NODE_ENV !== 'production') {
  middleware = [logger];
} else {
  middleware = [];
}
const store = createStore(RootReducers, applyMiddleware(...middleware));

export default store;
