import React, { useEffect, useState } from 'react';
import Sidebar from 'components/sidebar';
import { useSelector } from 'react-redux';
import useRecentPlaylits from 'hoc/recent-playlits';
import useNewRelease from 'hoc/new-release';
import useRecomendation from 'hoc/recomendation';
import useSetUser from 'hoc/user';
import AppInstance from 'utils/instance';
import useNewIndianRelease from 'hoc/indian-release';
import './index.scss';
import Content from '../content';

const Home = () => {
  const { getRecentPlaylits } = useRecentPlaylits();
  const { getUserData } = useSetUser();
  const { getNewRelease } = useNewRelease();
  const { getRecomended } = useRecomendation();
  const { getNewIndianRelease } = useNewIndianRelease();

  const [showError, setShowError] = useState(true);
  const [showSearch, setShowSearch] = useState(false);

  const token = AppInstance.getAccessToken();

  const { user } = useSelector((state) => ({
    user: state.user.user.data,
  }));

  if (window.location.hash) {
    window.location.hash = '';
  }

  useEffect(() => {
    getUserData(token);
    getRecentPlaylits();
    getNewRelease();
    getRecomended();
    getNewIndianRelease();
  }, []);

  if (!token) {
    AppInstance.removeAccessToken();
    window.location.href = '/';
    return null;
  }

  const checkPremiumUser = () => {
    if (user) {
      if (user.product === 'premium') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div className='home'>
      {!checkPremiumUser() && showError && (
        <div className='home-premium-error'>
          <div>
            <p>
              Live Audio Streaming is only available for premium spotify user.
              Streaming may not work for free users
            </p>
            <button onClick={() => setShowError(!showError)}>Okay</button>
          </div>
        </div>
      )}

      <div className='home-left'>
        <Sidebar setShowSearch={setShowSearch} />
      </div>
      <div className='home-right'>
        <div className='home-right-login'>
          <p>Hello, {user && user.display_name}</p>
        </div>
        <Content showSearch={showSearch} setShowSearch={setShowSearch} />
        {/* <SpotifyWebPlayer token={auth.token.access_token} uris={track} /> */}
      </div>
    </div>
  );
};

export default Home;
