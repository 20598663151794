import {
  SET_INDIAN_NEW_RELEASE,
  SET_NEW_RELEASE,
  SET_RECENT_PLAYLIST,
  SET_RECOMENDATION,
  SET_SEARCH_DATA,
  SET_TOKEN,
  SET_USER,
} from 'types/types';

export const setAccessToken = (payload) => ({
  type: SET_TOKEN,
  payload,
});

export const setRecentPlaylist = (payload) => ({
  type: SET_RECENT_PLAYLIST,
  payload,
});

export const setNewRelease = (payload) => ({
  type: SET_NEW_RELEASE,
  payload,
});

export const setUser = (payload) => ({
  type: SET_USER,
  payload,
});

export const setRecomendation = (payload) => ({
  type: SET_RECOMENDATION,
  payload,
});

export const setNewInidanRelease = (payload) => ({
  type: SET_INDIAN_NEW_RELEASE,
  payload,
});

export const setSearchData = (payload) => ({
  type: SET_SEARCH_DATA,
  payload,
});
