import React from 'react';
import { IoSearchOutline, IoHomeOutline } from 'react-icons/io5';

import './index.scss';

const Sidebar = ({ setShowSearch }) => {
  return (
    <div className='sidebar'>
      <h1>Music</h1>
      <ul className='sidebar-menu'>
        <li className='sidebar-menu-list'>
          <IoHomeOutline /> Home
        </li>
        <li className='sidebar-menu-list' onClick={() => setShowSearch(true)}>
          <IoSearchOutline /> Search
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
