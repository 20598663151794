const TOKEN = 'token';

class Instance {
  token;

  getAccessToken() {
    this.token = JSON.parse(sessionStorage.getItem(TOKEN));
    return this.token;
  }

  setAccessToken(token) {
    sessionStorage.setItem(TOKEN, JSON.stringify(token));
  }

  removeAccessToken() {
    sessionStorage.removeItem(TOKEN);
  }
}

const AppInstance = new Instance();

export default AppInstance;
