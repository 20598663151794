import AppInstance from 'utils/instance';

const data = AppInstance.getAccessToken();
let token;
if (data) {
  token = data;
} else {
  token = null;
}

export const SET_TOKEN = 'SET_TOKEN';
export let SET_TOKEN_INITIAL_STATE = {
  token,
};

export const SET_RECENT_PLAYLIST = 'SET_RECENT_PLAYLIST';
export const SET__RECENT_PLAYLIST_INITIAL_STATE = {
  playlist: {
    isLoading: false,
    isError: false,
    data: null,
  },
};

export const SET_NEW_RELEASE = 'SET_NEW_RELEASE';
export const SET_SET_NEW_RELEASE_INITIAL_STATE = {
  newRelease: {
    isLoading: false,
    isError: false,
    data: null,
  },
};

export const SET_USER = 'SET_USER';
export const SET_USER_INITIAL_STATE = {
  user: {
    isLoading: false,
    isError: false,
    data: null,
  },
};

export const SET_RECOMENDATION = 'SET_RECOMENDATION';
export const SET_RECOMENDATION_INITIAL_STATE = {
  recomendation: {
    isLoading: false,
    isError: false,
    data: null,
  },
};

export const SET_INDIAN_NEW_RELEASE = 'SET_INDIAN_NEW_RELEASE';
export const SET_INDIAN_NEW_RELEASE_INITIAL_STATE = {
  indian: {
    isLoading: false,
    isError: false,
    data: null,
  },
};

export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
export const SET_SEARCH_DATA_INITIAL_STATE = {
  search: {
    isLoading: false,
    isError: false,
    data: null,
  },
};
