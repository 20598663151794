import SpotifyWebApi from 'spotify-web-api-node';
import { useDispatch } from 'react-redux';
import { setSearchData } from 'actions/action';
import AppInstance from 'utils/instance';

const useSearch = () => {
  const dispatch = useDispatch();
  const webApi = new SpotifyWebApi();

  const token = AppInstance.getAccessToken();

  webApi.setAccessToken(token.access_token);

  const getSearchData = async (payload) => {
    dispatch(
      setSearchData({
        isLoading: true,
        isError: false,
        data: null,
      })
    );
    try {
      const data = await webApi.searchTracks(payload);
      dispatch(
        setSearchData({
          isLoading: false,
          isError: false,
          data: data.body,
        })
      );
    } catch (error) {
      AppInstance.removeAccessToken();
      dispatch(
        setSearchData({
          isLoading: false,
          isError: true,
          data: null,
        })
      );
    }
  };

  return { getSearchData };
};

export default useSearch;
