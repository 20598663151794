import { useSelector } from 'react-redux';

import SpotifyWebApi from 'spotify-web-api-node';
import { useDispatch } from 'react-redux';
import { setUser } from 'actions/action';
import AppInstance from 'utils/instance';

const useSetUser = () => {
  const dispatch = useDispatch();
  const webApi = new SpotifyWebApi();

  const token = AppInstance.getAccessToken();

  const getUserData = async (token) => {
    webApi.setAccessToken(token.access_token);

    dispatch(
      setUser({
        isLoading: true,
        isError: false,
        data: null,
      })
    );
    try {
      const data = await webApi.getMe();
      dispatch(
        setUser({
          isLoading: false,
          isError: false,
          data: data.body,
        })
      );
    } catch (error) {
      AppInstance.removeAccessToken();
      dispatch(
        setUser({
          isLoading: false,
          isError: true,
          data: null,
        })
      );
    }
  };

  return { getUserData };
};

export default useSetUser;
