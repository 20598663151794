// import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken } from 'actions/action';
import SpotifyWebApi from 'spotify-web-api-node';
import Login from 'components/login';
import Home from 'components/home';
import { accessUrl, getTokenFromResponse } from 'utils/auth';
import AppInstance from 'utils/instance';
import useSetUser from 'hoc/user';

const webApi = new SpotifyWebApi();

const App = () => {
  const dispatch = useDispatch();

  const { getUserData } = useSetUser();

  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));

  let _token;

  let localToken = AppInstance.getAccessToken();
  if (localToken) {
    _token = localToken;
  } else {
    _token = null;
  }

  if (!_token && window.location.hash) {
    _token = getTokenFromResponse();
    dispatch(setAccessToken(_token));
    webApi.setAccessToken(_token);
    AppInstance.setAccessToken(_token);
    if (_token) {
      getUserData(_token);
      window.location.hash = '';
    }
  }

  const showLogin = (user) => {
    if ((_token && _token.access_token) || auth.token) {
      return false;
    } else {
      return true;
    }
  };

  return showLogin() ? <Login accessUrl={accessUrl} /> : <Home />;
};

export default App;
