import { combineReducers } from 'redux';
import {
  setAccessTokenReducer,
  setRecentPlaylistReducer,
  setNewReleaseReducer,
  setUserReducer,
  setRecomendationReducer,
  setNewIndianRelease,
  setSearchData,
} from 'reducers/reducer';

const RootReducers = combineReducers({
  auth: setAccessTokenReducer,
  playlist: setRecentPlaylistReducer,
  newRelease: setNewReleaseReducer,
  user: setUserReducer,
  recomendation: setRecomendationReducer,
  indian: setNewIndianRelease,
  search: setSearchData,
});

export default RootReducers;
