import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import useSearch from 'hoc/search';
import SpotifyWebPlayer from 'react-spotify-web-playback/lib';
import AppInstance from 'utils/instance';

import './index.scss';

const Content = ({ showSearch, setShowSearch }) => {
  const token = AppInstance.getAccessToken().access_token;
  const [play, setPlay] = useState(false);

  const { getSearchData } = useSearch();

  const { playlist, newRelease, recomendation, indian, search } = useSelector(
    (state) => ({
      playlist: state.playlist.playlist,
      newRelease: state.newRelease.newRelease.data,
      indian: state.indian.indian.data,
      recomendation: state.recomendation.recomendation.data,
      search: state.search.search.data,
    })
  );

  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    setPlay(true);
  }, [tracks]);

  const handleSongClick = (url) => {
    let songs = [];
    songs.push(url.toString());
    console.log(songs);
    setTracks(songs);
  };

  const handleOnChange = (e) => {
    if (e.target.value === '') return;
    getSearchData(e.target.value);
  };

  return (
    <div className='content'>
      {showSearch && (
        <div className='content-search'>
          <div
            className={
              search ? 'content-search-close' : 'content-search-close-new'
            }
            onClick={() => {
              setShowSearch(false);
            }}
          >
            <IoIosCloseCircleOutline
              style={{ color: 'green', cursor: 'pointer' }}
            />
          </div>
          <input
            type='search'
            placeholder='search songs'
            onChange={(e) => handleOnChange(e)}
          />
          {search && (
            <div className='content-search-data'>
              {search.tracks.items.map((item, i) => {
                return (
                  <div
                    className='search-card'
                    onClick={() => handleSongClick(item.album.uri)}
                  >
                    <img
                      className='search-card-img'
                      src={item.album.images[0].url}
                      alt={item.album.name}
                    />
                    <div className='search-card-wrapper'>
                      <p className='search-card-artist'>
                        {item.album.name.substring(0, 20)}
                      </p>
                      <p className='search-card-album'>
                        Album -{item.album.album_type}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      <div className='content-wrapper'>
        <div className='content-recent-playlist'>
          {playlist && playlist.data && (
            <>
              <h3 className='content-header'>Recently Played Tracks</h3>
              <div className='content-playlist'>
                {playlist.data.items.map((item, i) => {
                  return (
                    <div
                      className='play-card'
                      onClick={() => handleSongClick(item.track.album.uri)}
                    >
                      <img
                        className='play-card-img'
                        src={item.track.album.images[0].url}
                        alt={item.track.album.name}
                      />
                      <p className='play-card-artist'>
                        {item.track.album.name.substring(0, 20)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>

        <div className='content-recent-playlist'>
          {newRelease && (
            <>
              <h3 className='content-header'>New Releases</h3>
              <div className='content-playlist'>
                {newRelease.albums.items.map((item, i) => {
                  return (
                    <div
                      className='play-card'
                      onClick={() => handleSongClick(item.uri)}
                    >
                      <img
                        className='play-card-img'
                        src={item.images[0].url}
                        alt={item.name}
                      />
                      <p className='play-card-artist'>
                        {item.name.substring(0, 20)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>

        <div className='content-recent-playlist'>
          {recomendation && (
            <>
              <h3 className='content-header'>Recomendation</h3>
              <div className='content-playlist'>
                {recomendation.tracks.map((item, i) => {
                  return (
                    <div
                      className='play-card'
                      onClick={() => handleSongClick(item.album.uri)}
                    >
                      <img
                        className='play-card-img'
                        src={item.album.images[0].url}
                        alt={item.album.name}
                      />
                      <p className='play-card-artist'>
                        {item.album.name.substring(0, 20)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>

        <div className='content-recent-playlist last-child'>
          {indian && (
            <>
              <h3 className='content-header'>More Songs</h3>
              <div className='content-playlist'>
                {indian.albums.items.map((item, i) => {
                  return (
                    <div
                      className='play-card'
                      onClick={() => handleSongClick(item.uri)}
                    >
                      <img
                        className='play-card-img'
                        src={item.images[0].url}
                        alt={item.name}
                      />
                      <p className='play-card-artist'>
                        {item.name.substring(0, 20)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>

      <div className='content-player'>
        <SpotifyWebPlayer
          uris={tracks}
          token={token}
          play={true}
          showSaveIcon
          styles={{
            activeColor: 'red',
            bgColor: '#181818',
            color: 'white',
            loaderColor: 'white',
            sliderColor: 'white',
            trackArtistColor: 'green',
            trackNameColor: 'white',
          }}
        />
      </div>
    </div>
  );
};

export default Content;
