import SpotifyWebApi from 'spotify-web-api-node';
import { useDispatch } from 'react-redux';
import { setRecomendation } from 'actions/action';
import AppInstance from 'utils/instance';

const useRecomendation = () => {
  const dispatch = useDispatch();
  const webApi = new SpotifyWebApi();

  const token = AppInstance.getAccessToken();

  webApi.setAccessToken(token.access_token);

  const getRecomended = async () => {
    dispatch(
      setRecomendation({
        isLoading: true,
        isError: false,
        data: null,
      })
    );
    try {
      const data = await webApi.getRecommendations({
        min_energy: 0.4,
        seed_artists: ['4zCH9qm4R2DADamUHMCa6O'],
        min_popularity: 50,
      });
      dispatch(
        setRecomendation({
          isLoading: false,
          isError: false,
          data: data.body,
        })
      );
    } catch (error) {
      AppInstance.removeAccessToken();
      dispatch(
        setRecomendation({
          isLoading: false,
          isError: true,
          data: null,
        })
      );
    }
  };

  return { getRecomended };
};

export default useRecomendation;
