import React from 'react';
import './index.scss';

const Login = (props) => {
  const { accessUrl } = props;
  return (
    <div className='login-container'>
      <p className='login-container-head'>Enter into new world of music</p>
      <a className='login-container-link' href={accessUrl}>
        Login
      </a>
    </div>
  );
};

export default Login;
