import {
  SET_INDIAN_NEW_RELEASE,
  SET_INDIAN_NEW_RELEASE_INITIAL_STATE,
  SET_NEW_RELEASE,
  SET_RECENT_PLAYLIST,
  SET_RECOMENDATION,
  SET_RECOMENDATION_INITIAL_STATE,
  SET_SEARCH_DATA,
  SET_SEARCH_DATA_INITIAL_STATE,
  SET_SET_NEW_RELEASE_INITIAL_STATE,
  SET_TOKEN,
  SET_TOKEN_INITIAL_STATE,
  SET_USER,
  SET_USER_INITIAL_STATE,
  SET__RECENT_PLAYLIST_INITIAL_STATE,
} from 'types/types';

export const setAccessTokenReducer = (
  state = SET_TOKEN_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const setRecentPlaylistReducer = (
  state = SET__RECENT_PLAYLIST_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case SET_RECENT_PLAYLIST:
      return {
        ...state,
        playlist: {
          isLoading: action.payload.isLoading,
          isError: action.payload.isError,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export const setNewReleaseReducer = (
  state = SET_SET_NEW_RELEASE_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case SET_NEW_RELEASE:
      return {
        ...state,
        newRelease: {
          isLoading: action.payload.isLoading,
          isError: action.payload.isError,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export const setUserReducer = (state = SET_USER_INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: {
          isLoading: action.payload.isLoading,
          isError: action.payload.isError,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export const setRecomendationReducer = (
  state = SET_RECOMENDATION_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case SET_RECOMENDATION:
      return {
        ...state,
        recomendation: {
          isLoading: action.payload.isLoading,
          isError: action.payload.isError,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export const setNewIndianRelease = (
  state = SET_INDIAN_NEW_RELEASE_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case SET_INDIAN_NEW_RELEASE:
      return {
        ...state,
        indian: {
          isLoading: action.payload.isLoading,
          isError: action.payload.isError,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export const setSearchData = (
  state = SET_SEARCH_DATA_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case SET_SEARCH_DATA:
      return {
        ...state,
        search: {
          isLoading: action.payload.isLoading,
          isError: action.payload.isError,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};
