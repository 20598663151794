const authEndpoint = 'https://accounts.spotify.com/authorize';
const clientId = '33efa3112dd24b248e9ad78cef127443';
const redirectUri = 'https://music.joshuadaniel.me/';
const scopes = [
  'streaming',
  'user-read-email',
  'user-read-private',
  'user-read-playback-state',
  'user-modify-playback-state',

  // 'user-read-currently-playing',
  // 'user-read-recently-played',
  // 'user-top-read',
  // 'user-library-read',
  // 'user-library-modify',
  // 'user-modify-playback-state',
];

export const accessUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
  '%20'
)}&response_type=token&show_dialog=true`;

export const getTokenFromResponse = () => {
  return window.location.hash
    .substring(1)
    .split('&')
    .reduce((initial, item) => {
      let parts = item.split('=');
      initial[parts[0]] = decodeURIComponent(parts[1]);
      return initial;
    }, {});
};
